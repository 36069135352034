import React from 'react'
export const reddit = {
  path: (
    <g key="1hw01TuI0">
      <path
        d="M11.492 12.232C11.5487 12.288 11.5487 12.3793 11.492 12.436C11.182 12.744 10.696 12.894 10.0047 12.894L9.99933 12.8927L9.994 12.894C9.30333 12.894 8.81667 12.744 8.50667 12.4353C8.45 12.3793 8.45 12.288 8.50667 12.232C8.56267 12.176 8.65467 12.176 8.71133 12.232C8.964 12.4833 9.38333 12.606 9.994 12.606L9.99933 12.6073L10.0047 12.606C10.6147 12.606 11.034 12.4833 11.2873 12.232C11.344 12.176 11.436 12.176 11.492 12.232ZM9.19867 10.62C9.19867 10.282 8.92267 10.0073 8.584 10.0073C8.24467 10.0073 7.96867 10.282 7.96867 10.62C7.96867 10.9573 8.24467 11.232 8.584 11.232C8.92267 11.2327 9.19867 10.958 9.19867 10.62ZM18 10C18 14.418 14.418 18 10 18C5.582 18 2 14.418 2 10C2 5.582 5.582 2 10 2C14.418 2 18 5.582 18 10ZM14.6667 9.914C14.6667 9.34667 14.2033 8.88533 13.6333 8.88533C13.3553 8.88533 13.1033 8.99667 12.9173 9.17533C12.2133 8.712 11.2607 8.41733 10.2067 8.37933L10.7833 6.56333L12.3453 6.92933L12.3433 6.952C12.3433 7.416 12.7227 7.79333 13.1887 7.79333C13.6547 7.79333 14.0333 7.416 14.0333 6.952C14.0333 6.488 13.6547 6.11067 13.1887 6.11067C12.8307 6.11067 12.526 6.334 12.4027 6.64667L10.7193 6.252C10.646 6.234 10.5707 6.27667 10.548 6.34867L9.90467 8.374C8.80067 8.38733 7.80133 8.68467 7.066 9.16133C6.88133 8.99133 6.63667 8.88467 6.366 8.88467C5.79667 8.88533 5.33333 9.34667 5.33333 9.914C5.33333 10.2913 5.54067 10.618 5.84533 10.7973C5.82533 10.9067 5.812 11.018 5.812 11.1307C5.812 12.6513 7.682 13.8887 9.98067 13.8887C12.2793 13.8887 14.1493 12.6513 14.1493 11.1307C14.1493 11.024 14.138 10.9193 14.12 10.816C14.444 10.642 14.6667 10.3053 14.6667 9.914ZM11.4187 10.008C11.0793 10.008 10.804 10.2827 10.804 10.6207C10.804 10.958 11.08 11.2327 11.4187 11.2327C11.7573 11.2327 12.0333 10.958 12.0333 10.6207C12.0333 10.2827 11.758 10.008 11.4187 10.008Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
