import React from 'react'
export const walletConnect_user = {
  path: (
    <>
      <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_7098_49247)" key="yS03X4YWy" />
      <path
        d="M15.0953 17.5478C18.3561 14.1827 23.6428 14.1827 26.9036 17.5478L27.296 17.9528C27.459 18.1211 27.459 18.3939 27.296 18.5621L25.9535 19.9475C25.872 20.0317 25.7399 20.0317 25.6583 19.9475L25.1183 19.3902C22.8435 17.0426 19.1554 17.0426 16.8806 19.3902L16.3023 19.9871C16.2207 20.0712 16.0886 20.0712 16.007 19.9871L14.6646 18.6017C14.5016 18.4334 14.5016 18.1606 14.6646 17.9923L15.0953 17.5478ZM29.6799 20.413L30.8746 21.646C31.0377 21.8143 31.0377 22.0871 30.8746 22.2553L25.4873 27.8152C25.3243 27.9835 25.0599 27.9835 24.8969 27.8152C24.8969 27.8152 24.8969 27.8152 24.8969 27.8152L21.0733 23.8692C21.0325 23.8271 20.9664 23.8271 20.9257 23.8692C20.9257 23.8692 20.9257 23.8692 20.9257 23.8692L17.1021 27.8152C16.9391 27.9835 16.6748 27.9835 16.5117 27.8152C16.5117 27.8152 16.5117 27.8152 16.5117 27.8152L11.1242 22.2553C10.9612 22.087 10.9612 21.8142 11.1242 21.646L12.319 20.4129C12.4821 20.2447 12.7464 20.2447 12.9094 20.4129L16.7331 24.359C16.7738 24.4011 16.8399 24.4011 16.8807 24.359C16.8807 24.359 16.8807 24.359 16.8807 24.359L20.7042 20.4129C20.8672 20.2447 21.1315 20.2447 21.2946 20.4129C21.2946 20.4129 21.2946 20.4129 21.2946 20.4129L25.1182 24.359C25.159 24.401 25.2251 24.401 25.2658 24.359L29.0894 20.413C29.2525 20.2447 29.5168 20.2447 29.6799 20.413Z"
        fill="#5399F5"
        key="aJ0FX8tbyw"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7098_49247"
          key="paint0_linear_7098_49247"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B6D5FF" key="gsKioDPX6T" />
          <stop offset="1" stopColor="#DBEFF5" key="YzKuSpHI0x" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 42 42',
}
