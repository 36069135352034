import React from 'react'
export const maker_circle_color = {
  path: (
    <g key="ba0dBh44F">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#1AAB9B"
        key="jjp-xGgi9"
      />
      <path
        d="M10.8374 14.6643C10.8374 14.8508 11.0023 15 11.2126 15C11.4173 15 11.5878 14.8508 11.5878 14.675V11.8242C11.5878 11.7123 11.5253 11.611 11.4287 11.5524L7.59124 9.06394C7.52871 9.02131 7.4548 9 7.37521 9C7.16487 9 7 9.1492 7 9.3357V14.6643C7 14.8508 7.16487 15 7.37521 15C7.58556 15 7.75043 14.8508 7.75043 14.6643V9.98579L10.8374 11.984V14.6643Z"
        fill="white"
        key="jQLP__ykQF"
      />
      <path
        d="M12.418 14.6643C12.418 14.8508 12.5828 15 12.7932 15C12.9978 15 13.1684 14.8508 13.1684 14.6643V11.984L16.2497 9.98579V14.6643C16.2497 14.8508 16.4146 15 16.6249 15C16.8353 15 17.0001 14.8508 17.0001 14.6643V9.3357C17.0001 9.12789 16.8068 9 16.6249 9C16.5453 9 16.4714 9.02131 16.4089 9.06394L12.5772 11.5471C12.4805 11.6057 12.418 11.7123 12.418 11.8188V14.6643Z"
        fill="white"
        key="j_Rfk06e_x"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
