import React from 'react'
export const circle_close = {
  path: (
    <>
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#F1F3F4"
        key="Uh-9gTRcX"
      />
      <path
        d="M11.0502 20.9502C10.6597 20.5597 10.6597 19.9265 11.0502 19.536L19.5355 11.0507C19.926 10.6602 20.5592 10.6602 20.9497 11.0507C21.3402 11.4412 21.3402 12.0744 20.9497 12.4649L12.4644 20.9502C12.0739 21.3407 11.4407 21.3407 11.0502 20.9502Z"
        fill="#787A9B"
        key="RZSZF9TqjR"
      />
      <path
        d="M20.9497 20.9502C20.5592 21.3407 19.926 21.3407 19.5355 20.9502L11.0502 12.4649C10.6597 12.0744 10.6597 11.4412 11.0502 11.0507C11.4407 10.6602 12.0739 10.6602 12.4644 11.0507L20.9497 19.536C21.3402 19.9265 21.3402 20.5597 20.9497 20.9502Z"
        fill="#787A9B"
        key="8_tmV4WwNz"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
