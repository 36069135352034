import React from 'react'
export const link_circle_mono = {
  path: (
    <>
      <path
        d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63616 2.6665 2.66663 8.63604 2.66663 15.9998C2.66663 23.3636 8.63616 29.3332 16 29.3332Z"
        fill="#787A9B"
        key="rOSICZycF"
      />
      <path
        d="M16.0001 8.11084L14.5391 8.94318L10.558 11.2231L9.09705 12.0554V19.9446L10.558 20.7769L14.5756 23.0568L16.0366 23.8892L17.4976 23.0568L21.4421 20.7769L22.9031 19.9446V12.0554L21.4421 11.2231L17.461 8.94318L16.0001 8.11084V8.11084ZM12.019 18.2799V13.7201L16.0001 11.4402L19.9812 13.7201V18.2799L16.0001 20.5598L12.019 18.2799Z"
        fill="white"
        key="4JNH2avsc6"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
