import React from 'react'
export const cbeth_eth = {
  path: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="16" r="9" fill="url(#paint0_linear_13180_1204)" key="nzbYamMO-" />
      <path
        d="M20.2304 10.4614V18.1121L16.5381 16.1026L20.2304 10.4614Z"
        fill="#FEFBFD"
        key="ff9xHBNIE0"
      />
      <path
        d="M20.2304 10.4614L23.9226 16.1026L20.2304 18.1121V10.4614Z"
        fill="#FEFBFD"
        key="IplCF0t__F"
      />
      <path
        d="M20.2303 18.756V21.5383L16.5381 16.7476L20.2303 18.756Z"
        fill="#FEFBFD"
        key="07GbZ6zrBB"
      />
      <path
        d="M20.2303 21.5383V18.756L23.9227 16.7476L20.2303 21.5383Z"
        fill="#FEFBFD"
        key="-k2jnMIVVK"
      />
      <circle cx="12" cy="16" r="9" fill="white" key="35g7Wgz4Kr" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24.2562C16.5598 24.2562 20.2562 20.5598 20.2562 16C20.2562 11.4402 16.5598 7.7438 12 7.7438C7.44023 7.7438 3.7438 11.4402 3.7438 16C3.7438 20.5598 7.44023 24.2562 12 24.2562ZM12 25C16.9706 25 21 20.9706 21 16C21 11.0294 16.9706 7 12 7C7.02944 7 3 11.0294 3 16C3 20.9706 7.02944 25 12 25Z"
        fill="url(#paint1_linear_13180_1204)"
        key="etugPG0uG-"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9752 9.30542L8.13248 15.7767L11.9748 18.1571L15.868 15.7767L11.9752 9.30542ZM11.9752 22.0991L8.13232 16.6323L11.9747 18.9751L15.8679 16.6323L11.9752 22.0991Z"
        fill="url(#paint2_linear_13180_1204)"
        key="wmibDnVlqD"
      />
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8331 15.798L11.9746 18.1571L8.1665 15.7979L11.9745 13.9917L15.8331 15.798Z"
        fill="white"
        key="TS3VouUskT"
      />
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.1416V9.34668L15.868 15.7767L12 18.1416ZM12 22.0642V18.9598L15.8678 16.6323L12 22.0642Z"
        fill="white"
        key="8ePL-XtDtF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13180_1204"
          key="paint0_linear_13180_1204"
          x1="11.6388"
          y1="16.0003"
          x2="28.8899"
          y2="29.5003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6580EB" key="4ro8seUsJV" />
          <stop offset="1" stopColor="#8EA2F2" key="wRDY2Zb5hW" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13180_1204"
          key="paint1_linear_13180_1204"
          x1="5.25374"
          y1="9.92499"
          x2="17.0662"
          y2="24.775"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#164BE1" key="krYfTr0g-W" />
          <stop offset="1" stopColor="#8DA6EC" key="uHTowWkgEa" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13180_1204"
          key="paint2_linear_13180_1204"
          x1="9.10089"
          y1="11.3844"
          x2="17.4034"
          y2="17.6953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#164BE1" key="M0B6XimZ_t" />
          <stop offset="1" stopColor="#8DA6EC" key="SnpYI9uHRg" />
        </linearGradient>
      </defs>
    </svg>
  ),
}
