import React from 'react'
export const omisego_circle_color = {
  path: (
    <g key="65_2Hz7KN">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#2759E7"
        key="EQJkdUd4V"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2844 9.93557C11.2844 10.6808 10.6808 11.285 9.9362 11.285C9.19159 11.285 8.58805 10.6808 8.58805 9.93557C8.58805 9.19034 9.19159 8.58641 9.93612 8.58641C10.6806 8.58641 11.2838 9.19076 11.2851 9.93582H11.2844V9.93557ZM9.93612 8C9.01547 7.99942 8.22176 8.64842 8.03879 9.55156C7.85581 10.4547 8.33407 11.3618 9.1818 11.7204C10.1691 12.1343 11.3057 11.671 11.7204 10.6856C12.0219 9.96909 11.8647 9.14201 11.3211 8.58549H11.8695V8H9.93612ZM14.0661 11.2857C13.3218 11.2857 12.718 10.6816 12.718 9.93632C12.718 9.191 13.3213 8.58691 14.0662 8.58691C14.8111 8.58691 15.4144 9.191 15.4144 9.93632C15.4132 10.6813 14.8099 11.2844 14.0662 11.2856L14.0661 11.2857ZM14.0661 8.00009C12.9981 8.00009 12.1322 8.86667 12.1322 9.93566C12.1322 11.0046 12.9981 11.8712 14.0661 11.8712C15.1341 11.8712 16 11.0046 16 9.93566C16 8.86667 15.1343 8 14.0662 8L14.0661 8.00009ZM9.93612 15.4145C9.19183 15.4145 8.58796 14.8104 8.58796 14.0651C8.58796 13.3198 9.19159 12.7157 9.93612 12.7157C10.6806 12.7157 11.2844 13.3198 11.2844 14.0651C11.2831 14.8097 10.6802 15.4136 9.93612 15.4145ZM9.93612 12.1289C8.86811 12.1289 8.00232 12.9955 8.00232 14.0644C8.00232 15.1334 8.8682 16 9.9362 16C11.0042 16 11.8701 15.1334 11.8701 14.0644C11.8701 12.9955 11.0043 12.1289 9.9362 12.1289H9.93612Z"
        fill="white"
        key="SSH_p88cAd"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
