import React from 'react'
export const increase = {
  path: (
    <path
      d="M4.70711 16.7071C4.31658 17.0976 3.68342 17.0976 3.29289 16.7071C2.90237 16.3166 2.90237 15.6834 3.29289 15.2929L13.5858 5H7C6.44772 5 6 4.55229 6 4C6 3.44771 6.44772 3 7 3H15.9993C16.0003 3 16.002 3 16.003 3C16.1375 3.0004 16.2657 3.02735 16.3828 3.07588C16.4999 3.12432 16.6096 3.19595 16.705 3.29078C16.7064 3.29219 16.7078 3.2936 16.7092 3.29502C16.9023 3.48924 16.9992 3.74301 17 3.997C17 3.998 17 3.999 17 4V13C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13V6.41421L4.70711 16.7071Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
