import React from 'react'
export const bal_circle_color = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.6665C23.3626 2.6665 29.3333 8.63717 29.3333 15.9998C29.3333 23.3625 23.3626 29.3332 16 29.3332C8.63729 29.3332 2.66663 23.3625 2.66663 15.9998C2.66663 8.63717 8.63729 2.6665 16 2.6665Z"
        fill="url(#bal_paint0_linear)"
        key="dZbGmeq-j"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6989 11.8159C11.6989 12.8452 13.7255 13.6826 16.2242 13.6826C18.7229 13.6826 20.7495 12.8452 20.7495 11.8159C20.7495 10.7866 18.7229 9.94922 16.2242 9.94922C13.7255 9.94922 11.6989 10.7866 11.6989 11.8159ZM16.2452 17.2775C13.0132 17.2775 10.3919 16.2641 10.3919 15.0135C10.3919 14.3201 11.1999 13.6988 12.4666 13.2855C13.3706 13.7575 14.7252 14.0588 16.2452 14.0588C17.7652 14.0588 19.1199 13.7575 20.0239 13.2855C21.2932 13.7015 22.0986 14.3201 22.0986 15.0135C22.1012 16.2641 19.4799 17.2775 16.2452 17.2775ZM16.2001 21.5735C11.9361 21.5735 8.4801 20.3415 8.4801 18.6908C8.4801 17.8295 9.42144 17.0535 10.9281 16.5068C12.1041 17.2615 14.0774 17.6481 16.3148 17.6481C18.4988 17.6481 20.4294 17.1761 21.6161 16.4535C23.0374 16.9948 23.9201 17.7495 23.9201 18.5815C23.9201 20.2348 20.4641 21.5735 16.2001 21.5735Z"
        fill="white"
        key="rinmZaJfcH"
      />
      <linearGradient
        id="bal_paint0_linear"
        key="bal_paint0_linear"
        x1="13.0055"
        y1="2.99988"
        x2="24.5055"
        y2="28.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222121" key="RhysULAyU_" />
        <stop offset="1" stopColor="#8E8E8E" key="2BO5-288TC" />
      </linearGradient>
    </>
  ),
  viewBox: '0 0 32 32',
}
