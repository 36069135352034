import React from 'react'
export const coins_cross = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H11C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4H4ZM4 13C3.44772 13 3 13.4477 3 14C3 14.5523 3.44772 15 4 15H10.416C10.1484 14.3875 10 13.7111 10 13H4ZM4 10H10.9996C10.5629 10.5814 10.25 11.2611 10.1 12H4C3.44772 12 3 11.5523 3 11C3 10.4477 3.44772 10 4 10ZM3 8C3 7.44772 3.44772 7 4 7H11C11.5523 7 12 7.44772 12 8C12 8.55228 11.5523 9 11 9H4C3.44772 9 3 8.55228 3 8ZM17.364 14.4143C17.7545 14.8048 17.7545 15.438 17.364 15.8285C16.9735 16.219 16.3403 16.219 15.9498 15.8285L14.5355 14.4142L13.1214 15.8284C12.7309 16.2189 12.0977 16.2189 11.7072 15.8284C11.3166 15.4379 11.3166 14.8047 11.7072 14.4142L13.1213 13L11.7071 11.5859C11.3166 11.1953 11.3166 10.5622 11.7071 10.1717C12.0977 9.78113 12.7308 9.78113 13.1213 10.1717L14.5355 11.5858L15.9498 10.1715C16.3403 9.781 16.9735 9.781 17.364 10.1715C17.7545 10.562 17.7545 11.1952 17.364 11.5857L15.9497 13L17.364 14.4143Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
