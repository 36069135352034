import React from 'react'
export const gnosisSafe_user = {
  path: (
    <>
      <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_7098_49275)" key="vMiXp2nIn" />
      <path
        d="M21.0039 11C19.0259 10.9992 17.0921 11.5851 15.4471 12.6834C13.8021 13.7818 12.5198 15.3433 11.7623 17.1705C11.0049 18.9977 10.8063 21.0085 11.1917 22.9485C11.5771 24.8886 12.5292 26.6708 13.9276 28.0697C15.3259 29.4686 17.1078 30.4214 19.0477 30.8076C20.9876 31.1937 22.9985 30.9959 24.8259 30.2392C26.6534 29.4824 28.2154 28.2007 29.3144 26.5561C30.4134 24.9115 31 22.978 31 21C30.9998 18.3486 29.9467 15.8057 28.0722 13.9305C26.1977 12.0553 23.6553 11.0012 21.0039 11ZM28.3227 21.546H23.6788C23.5405 22.239 23.1475 22.855 22.5774 23.2725C22.0072 23.6901 21.3013 23.8788 20.5989 23.8015C19.8964 23.7242 19.2485 23.3866 18.7827 22.8551C18.317 22.3236 18.0673 21.6369 18.0829 20.9304C18.0986 20.2239 18.3784 19.5489 18.8671 19.0385C19.3559 18.5281 20.0182 18.2194 20.7234 18.1732C21.4285 18.1271 22.1254 18.3469 22.6765 18.7892C23.2276 19.2315 23.593 19.8643 23.7006 20.5627H28.3188C28.4492 20.5627 28.5743 20.6145 28.6665 20.7067C28.7587 20.7989 28.8105 20.924 28.8105 21.0544C28.8105 21.1848 28.7587 21.3098 28.6665 21.402C28.5743 21.4942 28.4492 21.546 28.3188 21.546H28.3227Z"
        fill="white"
        key="DlR9_Ho-VE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7098_49275"
          key="paint0_linear_7098_49275"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop key="hhi37KZiu_" />
          <stop offset="1" stopColor="#8E8E8E" key="5J3sr-lYh_" />
        </linearGradient>
      </defs>
    </>
  ),
  viewBox: '0 0 42 42',
}
