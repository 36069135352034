import React from 'react'
export const arrow_up = {
  path: (
    <path
      d="M10.1007 22.7273H12.5346L12.5346 10.4677L17.182 15.1151L18.8482 13.4325L11.3176 5.90199L3.77077 13.4325L5.46964 15.1151L10.1007 10.4677L10.1007 22.7273Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 28 28',
}
