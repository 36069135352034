import React from 'react'
export const metamask_color = {
  path: (
    <g key="28TKg2tAs">
      <g clipPath="url(#clip0_metamask)">
        <path
          d="M20.6116 2.44287L13.1526 8.03834L14.5233 4.73046L20.6116 2.44287Z"
          fill="#E2761B"
          key="XCYJ9zRMe"
        />
        <path
          d="M3.38794 2.44287L10.7832 8.09204L9.47626 4.73046L3.38794 2.44287Z"
          fill="#E4761B"
          key="wbhAQ2aJO9"
        />
        <path
          d="M17.9235 15.4272L15.9365 18.4988L20.1867 19.6802L21.4086 15.4917L17.9235 15.4272Z"
          fill="#E4761B"
          key="OJwe1xNlfW"
        />
        <path
          d="M2.59106 15.4917L3.81298 19.6802L8.06312 18.4988L6.07618 15.4272L2.59106 15.4917Z"
          fill="#E4761B"
          key="SNK-Z9JzwG"
        />
        <path
          d="M7.81882 10.2292L6.6394 12.0335L10.8577 12.2268L10.7089 7.64087L7.81882 10.2292Z"
          fill="#E4761B"
          key="A7YAFZDXkz"
        />
        <path
          d="M16.1702 10.2292L13.2482 7.58716L13.1526 12.2268L17.3602 12.0335L16.1702 10.2292Z"
          fill="#E4761B"
          key="Pg7q5m8fgV"
        />
        <path
          d="M8.06323 18.4989L10.5921 17.253L8.40324 15.5239L8.06323 18.4989Z"
          fill="#E4761B"
          key="BrN_M7qTt0"
        />
        <path
          d="M13.397 17.253L15.9364 18.4989L15.5858 15.5239L13.397 17.253Z"
          fill="#E4761B"
          key="ZVMDV086Su"
        />
        <path
          d="M15.9364 18.499L13.397 17.2532L13.5989 18.9286L13.5776 19.6374L15.9364 18.499Z"
          fill="#D7C1B3"
          key="B5eJLcB6mW"
        />
        <path
          d="M8.06323 18.499L10.4221 19.6374L10.4114 18.9286L10.5921 17.2532L8.06323 18.499Z"
          fill="#D7C1B3"
          key="nm45KRsZaX"
        />
        <path
          d="M10.4645 14.4179L8.3501 13.7843L9.83765 13.0969L10.4645 14.4179Z"
          fill="#233447"
          key="BBQSauBPpY"
        />
        <path
          d="M13.5247 14.4179L14.1516 13.0969L15.6497 13.7843L13.5247 14.4179Z"
          fill="#233447"
          key="z2ZgcuzGrS"
        />
        <path
          d="M8.06311 18.4988L8.42437 15.4272L6.07617 15.4917L8.06311 18.4988Z"
          fill="#CD6116"
          key="7ARAFUx8sP"
        />
        <path
          d="M15.5752 15.4272L15.9365 18.4988L17.9234 15.4917L15.5752 15.4272Z"
          fill="#CD6116"
          key="pki3cSucei"
        />
        <path
          d="M17.3602 12.0334L13.1526 12.2268L13.5351 14.4177L14.162 13.0967L15.6602 13.784L17.3602 12.0334Z"
          fill="#CD6116"
          key="E9qEmYKNP1"
        />
        <path
          d="M8.35008 13.784L9.84826 13.0967L10.4645 14.4177L10.8577 12.2268L6.6394 12.0334L8.35008 13.784Z"
          fill="#CD6116"
          key="ocj6cjDwve"
        />
        <path
          d="M6.6394 12.0334L8.40321 15.5239L8.35009 13.784L6.6394 12.0334Z"
          fill="#E4751F"
          stroke="#E4751F"
          strokeWidth="0.07"
          strokeLinecap="round"
          strokeLinejoin="round"
          key="j-7k6hAHZ9K"
        />
        <path
          d="M15.6603 13.784L15.5859 15.5239L17.3604 12.0334L15.6603 13.784Z"
          fill="#E4751F"
          stroke="#E4751F"
          strokeWidth="0.07"
          strokeLinecap="round"
          strokeLinejoin="round"
          key="Xve87gfyzkg"
        />
        <path
          d="M10.8577 12.2268L10.4646 14.4177L10.9534 17.006L11.0702 13.6015L10.8577 12.2268Z"
          fill="#E4751F"
          key="nP7tPgn2Ab9"
        />
        <path
          d="M13.1528 12.2268L12.9509 13.5908L13.0359 17.006L13.5353 14.4177L13.1528 12.2268Z"
          fill="#E4751F"
          key="fiirW3VW0Cj"
        />
        <path
          d="M13.5353 14.4178L13.0359 17.0061L13.3972 17.2532L15.586 15.524L15.6603 13.7842L13.5353 14.4178Z"
          fill="#F6851B"
          key="y2Dsmp83t4M"
        />
        <path
          d="M8.3501 13.7842L8.40322 15.524L10.592 17.2532L10.9533 17.0061L10.4645 14.4178L8.3501 13.7842Z"
          fill="#F6851B"
          key="tbVrUTh5y-K"
        />
        <path
          d="M13.5778 19.6374L13.599 18.9286L13.4078 18.7568H10.5814L10.4114 18.9286L10.4221 19.6374L8.06323 18.499L8.89201 19.1864L10.5602 20.357H13.429L15.1078 19.1864L15.9366 18.499L13.5778 19.6374Z"
          fill="#C0AD9E"
          key="SMce69ERTXv"
        />
        <path
          d="M13.3971 17.2531L13.0358 17.0061H10.9533L10.592 17.2531L10.4114 18.9285L10.5814 18.7567H13.4077L13.599 18.9285L13.3971 17.2531Z"
          fill="#161616"
          key="p71NGwNiXgB"
        />
        <path
          d="M20.9197 8.40349L21.5679 5.31041L20.6116 2.44287L13.397 7.85576L16.1702 10.2293L20.0909 11.3892L20.9622 10.3581L20.5903 10.0896L21.1853 9.53118L20.7285 9.16602L21.3235 8.70421L20.9197 8.40349Z"
          fill="#763D16"
          key="wOuIBjRI10Q"
        />
        <path
          d="M2.44238 5.31041L3.0799 8.40349L2.66552 8.70421L3.27116 9.16602L2.81427 9.53118L3.40929 10.0896L3.0374 10.3581L3.89806 11.3892L7.81881 10.2293L10.592 7.85576L3.38804 2.44287L2.44238 5.31041Z"
          fill="#763D16"
          key="hTSauw5q7gC"
        />
        <path
          d="M20.0911 11.3892L16.1703 10.2292L17.3604 12.0335L15.5859 15.524L17.9235 15.4918H21.4086L20.0911 11.3892Z"
          fill="#F6851B"
          key="OuS3aJxy3Za"
        />
        <path
          d="M7.81873 10.2292L3.89798 11.3892L2.59106 15.4918H6.07618L8.40313 15.524L6.63932 12.0335L7.81873 10.2292Z"
          fill="#F6851B"
          key="5JUKOrumMAZ"
        />
        <path
          d="M13.1527 12.2269L13.3971 7.85577L14.534 4.73047H9.47632L10.592 7.85577L10.8576 12.2269L10.9532 13.6016V17.0061H13.0358L13.0464 13.6016L13.1527 12.2269Z"
          fill="#F6851B"
          key="p6DgvTITwUN"
        />
      </g>
      <defs>
        <clipPath id="clip0_metamask" key="clip0_metamask">
          <rect x="2.3999" y="2.3999" width="19.2" height="18" fill="white" key="1KA29rJScWm" />
        </clipPath>
      </defs>
    </g>
  ),
  viewBox: '0 0 24 24',
}
