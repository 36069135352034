import React from 'react'
export const press = {
  path: (
    <g key="uT-a2h8Sc">
      <path
        d="M17.2582 8.89477C17.2582 8.36421 17.2526 8.33053 17.2576 7.53838V4.625C17.2576 3.72754 16.5338 3 15.641 3C13.9995 4.42188 12.0534 6.25001 8.7705 6.25001H4.72904C4.05942 6.25001 3.5166 6.79565 3.5166 7.46876V9.90626C3.5166 10.5794 4.05942 11.125 4.72904 11.125H5.82182C5.34842 12.7665 5.88444 14.08 6.29705 15.3764C6.34004 15.5115 6.42847 15.6276 6.54767 15.7035C7.07255 16.0375 8.27426 16.101 8.91629 15.8342C9.3216 15.6658 9.42046 15.138 9.10629 14.8304C8.6388 14.3727 8.34508 13.8932 8.60629 13.2851C8.62681 13.2374 8.63515 13.1854 8.63054 13.1336C8.62593 13.0818 8.60852 13.032 8.57989 12.9888C8.16794 12.3621 8.48944 11.4118 9.3002 11.1415C12.2664 11.3253 14.0898 13.0314 15.641 14.375C16.5338 14.375 17.2576 13.6475 17.2576 12.75V9.83664C17.2582 8.89477 17.2576 9.83664 17.2582 9.22767V8.89477ZM15.641 12.2385C14.2616 11.0965 12.4828 9.87871 9.98293 9.57296V7.80204C12.4828 7.49628 14.2616 6.27854 15.641 5.13647V12.2385Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
