import React from 'react'
export const verified = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5553 9.97273C17.0804 9.48537 16.9745 8.74314 17.294 8.14043C17.5222 7.71067 17.5379 7.19787 17.3372 6.75452C17.1364 6.311 16.7417 5.98749 16.2702 5.87965C15.6089 5.72861 15.122 5.16189 15.0678 4.48062C15.0292 3.9947 14.7675 3.55455 14.3608 3.29103C13.9539 3.02751 13.4486 2.97039 12.9942 3.13679C12.3568 3.37007 11.6435 3.15871 11.2326 2.61519C10.9393 2.22768 10.4833 2 9.99998 2C9.51646 2 9.06062 2.22768 8.76734 2.61535C8.35646 3.15871 7.64287 3.37007 7.00591 3.13679C6.55135 2.97039 6.04575 3.02751 5.63919 3.29103C5.23231 3.55455 4.97071 3.99454 4.93199 4.48062C4.87776 5.16189 4.39072 5.72845 3.72976 5.87965C3.25808 5.98749 2.86336 6.311 2.66256 6.75468C2.4616 7.19803 2.4776 7.71067 2.70576 8.14059C3.02544 8.7433 2.91936 9.48537 2.44432 9.97289C2.10544 10.3207 1.94688 10.8079 2.01584 11.2906C2.08464 11.7732 2.3728 12.1956 2.7952 12.4332C3.3872 12.766 3.69616 13.4479 3.55792 14.1169C3.45936 14.594 3.58736 15.0903 3.904 15.4591C4.22064 15.8276 4.68976 16.026 5.17247 15.9954C5.84911 15.9525 6.47455 16.358 6.71679 16.9964C6.88991 17.4517 7.26367 17.7992 7.72767 17.9367C8.1915 18.0741 8.69262 17.9851 9.08222 17.6963C9.62846 17.2917 10.3721 17.2917 10.9181 17.6963C11.308 17.9851 11.8086 18.0741 12.2728 17.9367C12.7366 17.7992 13.1105 17.4517 13.2835 16.9964C13.5259 16.358 14.1515 15.9527 14.828 15.9954C15.3105 16.026 15.7795 15.8276 16.0961 15.4591C16.4129 15.0903 16.5408 14.5941 16.4424 14.1169C16.3041 13.4477 16.6128 12.766 17.2049 12.4332C17.6275 12.1958 17.9158 11.7732 17.9843 11.2906C18.0529 10.8078 17.8945 10.3206 17.5553 9.97273ZM8.78164 12.4142C9.01211 12.6297 9.37166 12.6253 9.59707 12.4045L13.5829 8.50023C13.8153 8.2726 13.8256 7.90179 13.6063 7.66159C13.3757 7.4092 12.9819 7.39828 12.7377 7.63752L9.19374 11.1102L7.26315 9.32465C7.01191 9.09229 6.61868 9.11215 6.39214 9.36866C6.1737 9.61599 6.19167 9.99223 6.4327 10.2176L8.78164 12.4142Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
