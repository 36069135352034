import React from 'react'
export const support_plus = {
  path: (
    <path
      d="M9.42857 19.7143C9.42857 20.1877 9.81233 20.5714 10.2857 20.5714C10.7591 20.5714 11.1429 20.1877 11.1429 19.7143V11.1429H19.7143C20.1877 11.1429 20.5714 10.7591 20.5714 10.2857C20.5714 9.81233 20.1877 9.42857 19.7143 9.42857H11.1429V0.857143C11.1429 0.383756 10.7591 0 10.2857 0C9.81232 0 9.42857 0.383756 9.42857 0.857143V9.42857H0.857143C0.383756 9.42857 0 9.81233 0 10.2857C0 10.7591 0.383756 11.1429 0.857143 11.1429H9.42857V19.7143Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 21',
}
