import React from 'react'
export const chevron = {
  path: (
    <path
      d="M9.61862 14.5513C9.81829 14.7864 10.1811 14.7864 10.3808 14.5513L16.0944 7.82366C16.3703 7.49888 16.1394 7 15.7133 7H4.28612C3.86001 7 3.62918 7.49888 3.90501 7.82366L9.61862 14.5513Z"
      fill="black"
    />
  ),
  viewBox: '0 0 20 20',
}
