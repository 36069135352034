import React from 'react'
export const arrow_decrease = {
  path: (
    <>
      <path
        d="M1.76559 0.634217C1.45317 0.321797 0.946636 0.321797 0.634217 0.634217C0.321797 0.946636 0.321797 1.45317 0.634217 1.76559L8.86853 9.9999H3.5999C3.15807 9.9999 2.7999 10.3581 2.7999 10.7999C2.7999 11.2417 3.15807 11.5999 3.5999 11.5999H11.5999V3.5999C11.5999 3.15807 11.2417 2.7999 10.7999 2.7999C10.3581 2.7999 9.9999 3.15807 9.9999 3.5999V8.86853L1.76559 0.634217Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 12 12',
}
