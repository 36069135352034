import React from 'react'
export const decrease = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.70711 0.292893C1.31658 -0.0976315 0.683417 -0.0976315 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L10.5858 12H4C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14H12.9993H13.0007C13.5526 14 14 13.5526 14 13.0007V12.9993V4C14 3.44771 13.5523 3 13 3C12.4477 3 12 3.44771 12 4V10.5858L1.70711 0.292893Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 14 14',
}
