import React from 'react'
export const send = {
  path: (
    <path
      d="M6.39837 6.51154L6.88762 11.6236L11.142 12.9691C11.2651 13.0083 11.3968 13.0103 11.5209 12.9746C11.645 12.9389 11.7561 12.8671 11.8403 12.7683C11.9245 12.6694 11.9781 12.5477 11.9946 12.4182C12.0111 12.2888 11.9896 12.1573 11.9329 12.0401L6.56854 0.836288C6.51675 0.72892 6.43617 0.638428 6.33599 0.575125C6.23581 0.511823 6.12006 0.478257 6.00193 0.478257C5.88381 0.478257 5.76805 0.511823 5.66787 0.575125C5.56769 0.638428 5.48712 0.72892 5.43533 0.836288L0.0670611 12.0323C0.0103649 12.1495 -0.0110763 12.281 0.00539415 12.4104C0.0218646 12.5399 0.0755228 12.6615 0.159721 12.7604C0.243918 12.8593 0.354956 12.9311 0.479075 12.9668C0.603194 13.0024 0.73494 13.0005 0.857991 12.9612L5.11238 11.6158L5.60163 6.5135C5.61136 6.414 5.65733 6.32172 5.7306 6.25459C5.80387 6.18746 5.8992 6.15028 5.99807 6.15028C6.09693 6.15028 6.19227 6.18746 6.26553 6.25459C6.3388 6.32172 6.38477 6.414 6.3945 6.5135L6.39837 6.51154Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 12 13',
}
