import React from 'react'
export const mana_color = {
  path: (
    <g key="1nF4iNnTb">
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="url(#mana_paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08496 8.5V16H15.335L9.08496 8.5Z"
        fill="url(#mana_paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83496 16H9.08496V8.5L2.83496 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20C7.67 21.255 9.75 22 12 22C14.25 22 16.33 21.255 18 20H6Z"
        fill="#FF2D55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18C4.57 18.755 5.245 19.43 6 20H18C18.755 19.43 19.43 18.755 20 18H4Z"
        fill="#FC9965"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.415 16H2.83496C3.14496 16.715 3.53996 17.385 3.99996 18H15.42V16H15.415Z"
        fill="#FFBC5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.415 12.5V18H20L15.415 12.5Z"
        fill="url(#mana_paint2_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.835 18H15.415V12.5L10.835 18Z"
        fill="white"
      />
      <path
        d="M15.415 11C16.7958 11 17.915 9.88071 17.915 8.5C17.915 7.11929 16.7958 6 15.415 6C14.0343 6 12.915 7.11929 12.915 8.5C12.915 9.88071 14.0343 11 15.415 11Z"
        fill="#FFC95B"
      />
      <path
        d="M9.08496 7C9.77532 7 10.335 6.44036 10.335 5.75C10.335 5.05964 9.77532 4.5 9.08496 4.5C8.3946 4.5 7.83496 5.05964 7.83496 5.75C7.83496 6.44036 8.3946 7 9.08496 7Z"
        fill="#FFC95B"
      />
      <defs>
        <linearGradient
          id="mana_paint0_linear"
          key="mana_paint0_linear"
          x1="19.0711"
          y1="4.92893"
          x2="4.92893"
          y2="19.0711"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2D55" />
          <stop offset="1" stopColor="#FFBC5B" />
        </linearGradient>
        <linearGradient
          id="mana_paint1_linear"
          key="mana_paint1_linear"
          x1="12.2079"
          y1="8.5"
          x2="12.2079"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A524B3" />
          <stop offset="1" stopColor="#FF2D55" />
        </linearGradient>
        <linearGradient
          id="mana_paint2_linear"
          key="mana_paint2_linear"
          x1="17.706"
          y1="12.5"
          x2="17.706"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A524B3" />
          <stop offset="1" stopColor="#FF2D55" />
        </linearGradient>
      </defs>
    </g>
  ),
  viewBox: '0 0 24 24',
}
