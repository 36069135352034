import React from 'react'
export const lock = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6V8C15.6569 8 17 9.34315 17 11V15C17 16.6569 15.6569 18 14 18H6C4.34315 18 3 16.6569 3 15V11C3 9.34315 4.34315 8 6 8V6ZM8 6C8 4.89543 8.89543 4 10 4C11.1046 4 12 4.89543 12 6V8H8V6ZM5 11C5 10.4477 5.44772 10 6 10H14C14.5523 10 15 10.4477 15 11V15C15 15.5523 14.5523 16 14 16H6C5.44772 16 5 15.5523 5 15V11Z"
      fill="#F6F6F6"
    />
  ),
  viewBox: '0 0 20 20',
}
