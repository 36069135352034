import React from 'react'
export const sai = {
  path: (
    <g key="NxYBwig3w">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2L2 12L12 22L22 12L12 2ZM12 5L5 12H9L12 8.99999L15 12H19L12 5Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
