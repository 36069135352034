import React from 'react'
export const metamask = {
  path: (
    <g key="YRxm5Zex3">
      <path
        d="M20.5087 2.61182L13.134 8.17252L14.4978 4.89178L20.5087 2.61182Z"
        fill="currentColor"
        key="RCWG0embn"
      />
      <path
        d="M3.48389 2.61182L10.7993 8.22519L9.50224 4.89178L3.48389 2.61182Z"
        fill="currentColor"
        key="JKYOOSyu4U"
      />
      <path
        d="M17.8552 15.5015L15.8911 18.5565L20.0936 19.7303L21.3017 15.5692L17.8552 15.5015Z"
        fill="currentColor"
        key="luReOlc_4l"
      />
      <path
        d="M2.70581 15.5692L3.90652 19.7303L8.10899 18.5565L6.14487 15.5015L2.70581 15.5692Z"
        fill="currentColor"
        key="xuC8VbJsWt"
      />
      <path
        d="M7.8715 10.3398L6.70044 12.1382L10.8733 12.3263L10.725 7.77393L7.8715 10.3398Z"
        fill="currentColor"
        key="MncHHKNyju"
      />
      <path
        d="M16.121 10.3398L13.2304 7.72119L13.134 12.3263L17.2994 12.1382L16.121 10.3398Z"
        fill="currentColor"
        key="hJIvXhHe3J"
      />
      <path
        d="M8.10913 18.5568L10.6143 17.3152L8.45007 15.5996L8.10913 18.5568Z"
        fill="currentColor"
        key="iuBiHhbJdi"
      />
      <path
        d="M13.3787 17.315L15.8912 18.5566L15.5948 15.4414L13.3787 17.315Z"
        fill="currentColor"
        key="VuEVmpdZNt"
      />
      <path
        d="M15.8912 18.5563L13.3787 17.3147L13.5788 18.9776L13.5565 19.6774L15.8912 18.5563Z"
        fill="currentColor"
        key="YBhC2orWfQ"
      />
      <path
        d="M8.10889 18.5565L10.4436 19.6777L10.4288 18.9779L10.6141 17.3149L8.10889 18.5565Z"
        fill="currentColor"
        key="mxNtF-33vx"
      />
      <path
        d="M10.4807 14.5007L8.39062 13.8761L9.86557 13.1914L10.4807 14.5007Z"
        fillOpacity="0"
        key="MorYPlIEEv"
      />
      <path
        d="M13.5117 14.5007L14.1269 13.1914L15.6092 13.8761L13.5117 14.5007Z"
        fillOpacity="0"
        key="bRY4b371yR"
      />
      <path
        d="M8.10889 18.5567L8.46466 15.5017L6.14478 15.5694L8.10889 18.5567Z"
        fill="currentColor"
        key="ixccM6OFk3"
      />
      <path
        d="M15.5356 15.5017L15.8914 18.5567L17.8555 15.5694L15.5356 15.5017Z"
        fill="currentColor"
        key="xuAqGwUERV"
      />
      <path
        d="M17.2994 12.1384L13.134 12.3265L13.5194 14.5012L14.1346 13.1919L15.617 13.8766L17.2994 12.1384Z"
        fill="currentColor"
        key="0TLQXVIi-z"
      />
      <path
        d="M8.39081 13.8766L9.87316 13.1919L10.4809 14.5012L10.8738 12.3265L6.70093 12.1384L8.39081 13.8766Z"
        fill="currentColor"
        key="qfpVDvaH8R"
      />
      <path
        d="M6.70044 12.1384L8.44962 15.5998L8.39032 13.8766L6.70044 12.1384Z"
        fill="currentColor"
        key="xZgu_dNoNe_"
      />
      <path
        d="M15.6173 13.8766L15.5432 15.5998L17.2998 12.1384L15.6173 13.8766Z"
        fill="currentColor"
        key="eyX2W4iu3Lx"
      />
      <path
        d="M10.8738 12.3267L10.481 14.5013L10.9701 17.0672L11.0813 13.6886L10.8738 12.3267Z"
        fill="currentColor"
        key="vzegty-bPcZ"
      />
      <path
        d="M13.1342 12.3267L12.9341 13.6811L13.023 17.0672L13.5196 14.5013L13.1342 12.3267Z"
        fill="currentColor"
        key="eYc70sIlDE_"
      />
      <path
        d="M13.5193 14.5008L13.0227 17.0667L13.3785 17.315L15.5427 15.5994L15.6168 13.8762L13.5193 14.5008Z"
        fill="currentColor"
        key="NiTMlBjELXM"
      />
      <path
        d="M8.39062 13.8762L8.44992 15.5994L10.6142 17.315L10.9699 17.0667L10.4807 14.5008L8.39062 13.8762Z"
        fill="currentColor"
        key="yy-U901cERl"
      />
      <path
        d="M13.5565 19.6776L13.6677 18.752L13.3935 18.8122H10.5992L10.3324 18.752L10.4436 19.6776L8.10889 18.5564L8.92418 19.2336L10.577 20.3999H13.4157L15.0759 19.2336L15.8912 18.5564L13.5565 19.6776Z"
        fill="currentColor"
        key="RX4SOlzD0Bl"
      />
      <path
        d="M13.3786 17.3147L13.0228 17.0664H10.9698L10.614 17.3147L10.455 18.7415C10.4462 18.821 10.5418 18.8679 10.5992 18.8121H13.3934C13.4579 18.8697 13.5594 18.8168 13.549 18.731L13.3786 17.3147Z"
        fillOpacity="0"
        key="aczG4nrNCQq"
      />
      <path
        d="M20.8201 8.5337L21.4501 5.46365L20.5088 2.61182L13.3787 7.98441L16.121 10.3396L19.9974 11.4909L20.8571 10.4751L20.4865 10.2042L21.0795 9.65487L20.62 9.29369L21.2129 8.83469L20.8201 8.5337Z"
        fill="currentColor"
        key="O-YZh4BMrYC"
      />
      <path
        d="M2.55005 5.46365L3.18005 8.5337L2.77981 8.83469L3.37275 9.29369L2.92064 9.65487L3.51358 10.2042L3.14299 10.4751L3.99534 11.4909L7.87169 10.3396L10.614 7.98441L3.48393 2.61182L2.55005 5.46365Z"
        fill="currentColor"
        key="OJRg5cpdaTr"
      />
      <path
        d="M19.9974 11.4911L16.1211 10.3398L17.2996 12.1382L15.543 15.5996L17.8554 15.5695H21.3019L19.9974 11.4911Z"
        fill="currentColor"
        key="UUYCeYTk7rm"
      />
      <path
        d="M7.87181 10.3398L3.99546 11.4911L2.70581 15.5695H6.14487L8.44993 15.5996L6.70075 12.1382L7.87181 10.3398Z"
        fill="currentColor"
        key="IYKdosm9Owa"
      />
      <path
        d="M13.1342 12.3264L13.3788 7.98471L14.5054 4.89209H9.50244L10.6142 7.98471L10.8736 12.3264L10.9626 13.6959L10.97 17.0669H13.023L13.0379 13.6959L13.1342 12.3264Z"
        fill="currentColor"
        key="mmSc3cSox62"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
