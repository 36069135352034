import React from 'react'
export const medium = {
  path: (
    <g key="eyTTxQHSE">
      <path
        d="M3.89729 7.10279C3.91717 6.9434 3.84227 6.78587 3.69561 6.6786L2.20168 5.21816V5H6.84034L10.4258 11.3811L13.578 5H18V5.21816L16.7227 6.21198C16.6126 6.2801 16.5579 6.39205 16.5808 6.50286V13.8051C16.5579 13.9159 16.6126 14.0278 16.7227 14.0959L17.9701 15.0898V15.3079H11.6956V15.0898L12.9879 14.0717C13.1148 13.9687 13.1148 13.9384 13.1148 13.7808V7.87846L9.52194 15.2837H9.03641L4.85341 7.87846V12.8415C4.81853 13.0502 4.90393 13.2603 5.08497 13.4112L6.76564 15.0655V15.2837H2V15.0655L3.68067 13.4112C3.86039 13.26 3.94081 13.0485 3.89729 12.8415V7.10279Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
