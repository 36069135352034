import React from 'react'
export const latamex = {
  path: (
    <>
      <path
        className="st0"
        fill="#3C2FED"
        d="M122.1,117.9H79.2c-1.8,0-3.5-1.2-4-2.9l-14-44.6c-0.7-2.2,0.5-4.6,2.8-5.3c2.2-0.7,4.6,0.5,5.3,2.8 l13.1,41.7h39.8c2.3,0,4.2,1.9,4.2,4.2S124.4,117.9,122.1,117.9z"
        key="0ln4QENR7"
      />
      <path
        className="st0"
        fill="#3C2FED"
        d="M47,73L47,73H23.8c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2l0,0H47c2.3,0,4.2,1.9,4.2,4.2 C51.2,71.1,49.3,73,47,73z"
        key="rIqe1tgASa"
      />
      <path
        className="st0"
        fill="#3C2FED"
        d="M53.3,95.5L53.3,95.5H23.8c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2l0,0h29.5c2.3,0,4.2,1.9,4.2,4.2 C57.5,93.6,55.6,95.5,53.3,95.5z"
        key="zLTB1zhSOm"
      />
      <path
        className="st0"
        fill="#3C2FED"
        d="M61,117.9L61,117.9H37.8c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2l0,0H61c2.3,0,4.2,1.9,4.2,4.2 S63.3,117.9,61,117.9z"
        key="L5UXKxMlMk"
      />
      <path
        className="st1"
        fill="#3386FF"
        d="M94.9,95.1c-1.8,0-3.5-1.1-4-2.9L77.8,50.5H37.8c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2h42.9 c1.8,0,3.5,1.2,4,2.9l14,44.6c0.7,2.2-0.5,4.6-2.8,5.3C95.6,95.1,95.2,95.1,94.9,95.1z"
        key="lo4kZ5RNij"
      />
      <path
        className="st1"
        fill="#3386FF"
        d="M136.2,95.5L136.2,95.5H113c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2l0,0h23.2c2.3,0,4.2,1.9,4.2,4.2 C140.4,93.6,138.4,95.5,136.2,95.5z"
        key="uz2ZNVANZE"
      />
      <path
        className="st1"
        fill="#3386FF"
        d="M136.2,73h-29.5c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2h29.5c2.3,0,4.2,1.9,4.2,4.2S138.4,73,136.2,73z"
        key="VYXwptK2Ko"
      />
      <path
        className="st1"
        fill="#3386FF"
        d="M122.1,50.5H98.9c-2.3,0-4.2-1.9-4.2-4.2c0-2.3,1.9-4.2,4.2-4.2h23.2c2.3,0,4.2,1.9,4.2,4.2 C126.4,48.6,124.4,50.5,122.1,50.5z"
        key="ZphIkDiK4p"
      />
    </>
  ),
  viewBox: '20 10 140 140',
}
