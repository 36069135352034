import React from 'react'
export const wyre = {
  path: (
    <>
      <path
        d="M12.0321 9.99683H18.0743L14.8068 18.0968C14.338 19.2478 13.234 20 12.0049 20H5.9624L9.22959 11.9256C9.69834 10.7746 10.8024 9.99745 12.0318 9.99745"
        fill="#A9CAFF"
        key="f_aKEqPZl"
      />
      <path
        d="M7.73755 15.5567H11.0932C12.3222 15.5567 12.7138 15.5077 13.7385 14.6027L18.0666 9.94019H12.0322C10.8029 9.94019 9.69567 10.7167 9.23005 11.8677L7.73755 15.5567Z"
        fill="#0408E0"
        key="MJhpxA61a-"
      />
      <path
        d="M10.0137 5.05469H16.0559L12.7884 13.1084C12.3196 14.2594 11.2156 14.9459 9.98652 14.9459H3.94434L7.21152 6.93656C7.68027 5.78563 8.78433 5.05469 10.0137 5.05469"
        fill="#0384FF"
        key="Vl7o5oJ3ZJ"
      />
      <path
        d="M5.68652 10.6429H9.07902C10.3081 10.6429 10.7 10.4763 11.7243 9.57161L16.0415 5.02661H10.0137C8.78465 5.02661 7.67715 5.75567 7.21152 6.90661L5.68652 10.6429Z"
        fill="#0408E0"
        key="8I7H3xjwK4"
      />
      <path
        d="M7.99509 0H14.0376L10.7701 8.12C10.3013 9.27094 9.19727 9.99812 7.96821 9.99812H1.92603L5.19321 1.94813C5.65884 0.7975 6.76571 0 7.99509 0Z"
        fill="#004EFF"
        key="BzAohzJUFV"
      />
    </>
  ),
  viewBox: '0 0 20 20',
}
