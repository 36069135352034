import React from 'react'
export const knc_circle_color = {
  path: (
    <>
      <path
        d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63616 2.6665 2.66663 8.63604 2.66663 15.9998C2.66663 23.3636 8.63616 29.3332 16 29.3332Z"
        fill="url(#knc_paint0_linear)"
        key="o9MAdG8TN"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2044 8.06425L21.2871 11.0651C21.4693 11.2012 21.4507 11.4697 21.2499 11.5808L15.2039 14.9254L16.695 8.24688C16.7433 8.02486 17.0148 7.92459 17.2044 8.06425ZM21.4199 19.4211L15.4428 16.002L21.4199 12.5793C21.6299 12.4602 21.8906 12.6082 21.8906 12.8501V19.1504C21.8906 19.3886 21.6299 19.5403 21.4199 19.4211ZM17.2005 23.9373L21.2867 20.9358C21.4689 20.7997 21.4503 20.531 21.2533 20.42L15.2039 17.0746L16.6949 23.7546C16.7395 23.9767 17.0146 24.0734 17.2005 23.9373ZM15.4352 8.61903L13.8898 15.8825L15.4352 23.1423C15.4961 23.4278 15.1741 23.6337 14.9451 23.4567L10.9886 20.4068C10.7847 20.2514 10.6666 20.0056 10.6666 19.7491V12.0122C10.6666 11.7557 10.7847 11.5099 10.9886 11.3546L14.9451 8.30464C15.1741 8.12757 15.4961 8.33355 15.4352 8.61903Z"
        fill="white"
        key="71WJ7L04zB"
      />
      <linearGradient
        id="knc_paint0_linear"
        key="knc_paint0_linear"
        x1="7.50549"
        y1="6.99988"
        x2="22.5055"
        y2="25.9999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00AF87" key="eeIIStpGWr" />
        <stop offset="1" stopColor="#1DE3B6" key="4J9cx9zzB2" />
      </linearGradient>
    </>
  ),
  viewBox: '0 0 32 32',
}
