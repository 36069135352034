import React from 'react'
export const caret_down = {
  path: (
    <path
      d="M3.77156 4.73095C3.89137 4.87202 4.10908 4.87202 4.22889 4.73095L7.65705 0.694394C7.82255 0.499524 7.68405 0.200195 7.42838 0.200195H0.572062C0.316397 0.200195 0.1779 0.499524 0.343399 0.694394L3.77156 4.73095Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 8 5',
}
