import React from 'react'
export const metamask_user = {
  path: (
    <>
      <circle cx="21" cy="21" r="21" fill="url(#paint0_linear_7098_49387)" key="cMQyMy7m9" />
      <g clipPath="url(#clip0_7098_49387)">
        <path
          d="M29.6133 11.4429L22.1543 17.0383L23.525 13.7305L29.6133 11.4429Z"
          fill="#E2761B"
          key="3tUHrz0Yrj"
        />
        <path
          d="M12.3887 11.4429L19.7839 17.092L18.477 13.7305L12.3887 11.4429Z"
          fill="#E4761B"
          key="iOpDQoh0la"
        />
        <path
          d="M26.9244 24.4273L24.9375 27.4989L29.1876 28.6803L30.4096 24.4917L26.9244 24.4273Z"
          fill="#E4761B"
          key="KXL6VStsb4"
        />
        <path
          d="M11.5918 24.4917L12.8137 28.6803L17.0639 27.4989L15.0769 24.4273L11.5918 24.4917Z"
          fill="#E4761B"
          key="LJUL4PIaIi"
        />
        <path
          d="M16.82 19.2292L15.6406 21.0335L19.8589 21.2268L19.7101 16.6409L16.82 19.2292Z"
          fill="#E4761B"
          key="pD5UM451P-"
        />
        <path
          d="M25.1719 19.2292L22.2499 16.5872L22.1543 21.2268L26.3619 21.0335L25.1719 19.2292Z"
          fill="#E4761B"
          key="JYomlgevQW"
        />
        <path
          d="M17.0645 27.499L19.5933 26.2532L17.4045 24.524L17.0645 27.499Z"
          fill="#E4761B"
          key="U4MViSo5TB"
        />
        <path
          d="M22.3984 26.2532L24.9379 27.499L24.5873 24.524L22.3984 26.2532Z"
          fill="#E4761B"
          key="j5knjvCFj0"
        />
        <path
          d="M24.9379 27.4989L22.3984 26.2531L22.6003 27.9285L22.5791 28.6374L24.9379 27.4989Z"
          fill="#D7C1B3"
          key="2n6ofHSM3b"
        />
        <path
          d="M17.0645 27.4989L19.4233 28.6374L19.4127 27.9285L19.5933 26.2531L17.0645 27.4989Z"
          fill="#D7C1B3"
          key="e65F1299QN"
        />
        <path
          d="M19.466 23.4179L17.3516 22.7842L18.8391 22.0969L19.466 23.4179Z"
          fill="#233447"
          key="zyYi6CTYvv"
        />
        <path
          d="M22.5254 23.4179L23.1523 22.0969L24.6505 22.7842L22.5254 23.4179Z"
          fill="#233447"
          key="ClPaCQNf71"
        />
        <path
          d="M17.0651 27.4989L17.4263 24.4273L15.0781 24.4917L17.0651 27.4989Z"
          fill="#CD6116"
          key="oVa8JMmEF7"
        />
        <path
          d="M24.5762 24.4273L24.9374 27.4989L26.9244 24.4917L24.5762 24.4273Z"
          fill="#CD6116"
          key="FwzVFyGU95"
        />
        <path
          d="M26.3619 21.0336L22.1543 21.2269L22.5368 23.4178L23.1637 22.0968L24.6619 22.7842L26.3619 21.0336Z"
          fill="#CD6116"
          key="qphMI5brcO"
        />
        <path
          d="M17.3513 22.7842L18.8495 22.0968L19.4657 23.4178L19.8589 21.2269L15.6406 21.0336L17.3513 22.7842Z"
          fill="#CD6116"
          key="vn5ewi5tHUf"
        />
        <path
          d="M15.6406 21.0336L17.4044 24.524L17.3513 22.7842L15.6406 21.0336Z"
          fill="#E4751F"
          stroke="#E4751F"
          strokeWidth="0.07"
          strokeLinecap="round"
          strokeLinejoin="round"
          key="D21mcc9zh4O"
        />
        <path
          d="M24.6603 22.7842L24.5859 24.524L26.3604 21.0336L24.6603 22.7842Z"
          fill="#E4751F"
          stroke="#E4751F"
          strokeWidth="0.07"
          strokeLinecap="round"
          strokeLinejoin="round"
          key="0Jafb3tL-rC"
        />
        <path
          d="M19.858 21.2269L19.4648 23.4178L19.9536 26.0061L20.0705 22.6016L19.858 21.2269Z"
          fill="#E4751F"
          key="pdJm5FynKU-"
        />
        <path
          d="M22.1531 21.2269L21.9512 22.5908L22.0362 26.0061L22.5356 23.4178L22.1531 21.2269Z"
          fill="#E4751F"
          key="Yi7hO4eboM0"
        />
        <path
          d="M22.5365 23.4178L22.0371 26.0061L22.3984 26.2532L24.5872 24.524L24.6616 22.7842L22.5365 23.4178Z"
          fill="#F6851B"
          key="8G9g6VIaiuy"
        />
        <path
          d="M17.3516 22.7842L17.4047 24.524L19.5935 26.2532L19.9548 26.0061L19.466 23.4178L17.3516 22.7842Z"
          fill="#F6851B"
          key="6S2UWGA_UZn"
        />
        <path
          d="M22.579 28.6374L22.6003 27.9286L22.409 27.7568H19.5827L19.4127 27.9286L19.4233 28.6374L17.0645 27.499L17.8932 28.1864L19.5614 29.357H22.4303L24.1091 28.1864L24.9378 27.499L22.579 28.6374Z"
          fill="#C0AD9E"
          key="YrIQIUN_Jtc"
        />
        <path
          d="M22.3978 26.2531L22.0366 26.0061H19.954L19.5927 26.2531L19.4121 27.9285L19.5821 27.7567H22.4085L22.5997 27.9285L22.3978 26.2531Z"
          fill="#161616"
          key="TqkLXag4rlU"
        />
        <path
          d="M29.9212 17.4035L30.5693 14.3104L29.613 11.4429L22.3984 16.8558L25.1717 19.2293L29.0924 20.3892L29.9637 19.3581L29.5918 19.0896L30.1868 18.5312L29.7299 18.166L30.3249 17.7042L29.9212 17.4035Z"
          fill="#763D16"
          key="yDcXue6598B"
        />
        <path
          d="M11.4434 14.3104L12.0809 17.4035L11.6665 17.7042L12.2721 18.166L11.8152 18.5312L12.4103 19.0896L12.0384 19.3581L12.899 20.3892L16.8198 19.2293L19.593 16.8558L12.389 11.4429L11.4434 14.3104Z"
          fill="#763D16"
          key="YyatvqhMWOK"
        />
        <path
          d="M29.0911 20.3892L25.1703 19.2293L26.3604 21.0336L24.5859 24.5241L26.9235 24.4918H30.4086L29.0911 20.3892Z"
          fill="#F6851B"
          key="56blIviGBzt"
        />
        <path
          d="M16.8195 19.2293L12.8987 20.3892L11.5918 24.4918H15.0769L17.4039 24.5241L15.6401 21.0336L16.8195 19.2293Z"
          fill="#F6851B"
          key="9L9h0Hd91Dr"
        />
        <path
          d="M22.1529 21.2269L22.3973 16.8558L23.5342 13.7305H18.4766L19.5922 16.8558L19.8579 21.2269L19.9535 22.6016V26.0061H22.0361L22.0467 22.6016L22.1529 21.2269Z"
          fill="#F6851B"
          key="gdHlPux94Ga"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_7098_49387"
          key="paint0_linear_7098_49387"
          x1="21"
          y1="0"
          x2="21"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE1A8" key="JBMuDI5nB4B" />
          <stop offset="1" stopColor="#FFD2F0" key="vD-BCV1kvMl" />
        </linearGradient>
        <clipPath id="clip0_7098_49387" key="clip0_7098_49387">
          <rect
            width="19.2"
            height="18"
            fill="white"
            transform="translate(11.4004 11.3999)"
            key="7-Py33A1x76"
          />
        </clipPath>
      </defs>
    </>
  ),
  viewBox: '0 0 42 42',
}
