import React from 'react'
export const mana_circle_mono = {
  path: (
    <>
      <circle cx="16" cy="16.0003" r="13.3333" fill="black" key="yzpqXKL4CE" />
      <path
        d="M16 29.3337C23.3638 29.3337 29.3333 23.3641 29.3333 16.0003C29.3333 8.63653 23.3638 2.66699 16 2.66699C8.63616 2.66699 2.66663 8.63653 2.66663 16.0003C2.66663 23.3641 8.63616 29.3337 16 29.3337Z"
        fill="#787A9B"
        key="8PrhkDdId"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1134 11.334V21.334H20.4467L12.1134 11.334Z"
        fill="#666786"
        key="HyTUvZ3lOs"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78003 21.334H12.1134V11.334L3.78003 21.334Z"
        fill="white"
        key="ALeS_ISA-h"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 26.667C10.2267 28.3403 13 29.3337 16 29.3337C19 29.3337 21.7733 28.3403 24 26.667H8Z"
        fill="#787A9B"
        key="a1PpXXwRap"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33325 24C6.09325 25.0067 6.99325 25.9067 7.99992 26.6667H23.9999C25.0066 25.9067 25.9066 25.0067 26.6666 24H5.33325Z"
        fill="#666786"
        key="ay8Fu7zuWF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5534 21.334H3.78003C4.19336 22.2873 4.72003 23.1807 5.33336 24.0007H20.56V21.334H20.5534Z"
        fill="#787A9B"
        key="XkI-eqN8iZ"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5532 16.667V24.0003H26.6666L20.5532 16.667Z"
        fill="#666786"
        key="hT357fFyj8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4465 24.0003H20.5532V16.667L14.4465 24.0003Z"
        fill="white"
        key="aqZvDzPFX5"
      />
      <path
        d="M20.5533 14.6667C22.3943 14.6667 23.8866 13.1743 23.8866 11.3333C23.8866 9.49238 22.3943 8 20.5533 8C18.7124 8 17.22 9.49238 17.22 11.3333C17.22 13.1743 18.7124 14.6667 20.5533 14.6667Z"
        fill="white"
        key="nD_bRMANO6"
      />
      <path
        d="M12.1133 9.33333C13.0338 9.33333 13.78 8.58714 13.78 7.66667C13.78 6.74619 13.0338 6 12.1133 6C11.1928 6 10.4467 6.74619 10.4467 7.66667C10.4467 8.58714 11.1928 9.33333 12.1133 9.33333Z"
        fill="white"
        key="xhse_xfm_4"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
