import React from 'react'
export const arrow_down = {
  path: (
    <path
      d="M8.5498 0.727272H6.61088V12.4503L2.129 7.96839L0.80158 9.31072L7.58034 16.0895L14.374 9.31072L13.0168 7.96839L8.5498 12.4503V0.727272Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 15 17',
}
