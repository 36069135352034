import React from 'react'
export const question_mark = {
  path: (
    <g key="lYEuh4ScW">
      <path
        d="M14.6266 8.68682C14.9158 8.17276 15 7.62651 15 7C15 5.76305 14.6186 4.77517 13.735 4.08441C12.8515 3.37758 11.5422 3 9.99999 3C8.5542 3 7.46998 3.39365 6.55431 4.1326C5.86848 4.68608 5.38993 5.40178 5.11865 6.27969L7.78038 6.94512C7.89633 6.49616 8.13021 6.1209 8.48202 5.81935C8.93182 5.43381 9.48604 5.24104 10.1447 5.24104C10.8354 5.24104 11.3656 5.40971 11.735 5.74706C12.1045 6.06835 12.2893 6.51815 12.2893 7.09646C12.2893 7.54626 12.1447 7.9559 11.8555 8.32537C11.5664 8.69485 11.0764 9.12056 10.3856 9.60248C9.74307 10.0523 9.25311 10.5422 8.91576 11.0724C8.74863 11.327 8.60121 11.6408 8.49109 11.9728C8.31115 12.5151 8.76781 13 9.33922 13H10.0408C10.5769 13 10.9825 12.5629 11.1634 12.0582C11.2012 11.9528 11.2467 11.8551 11.3013 11.7712C11.5262 11.4499 11.8636 11.1286 12.3134 10.8073L13.0603 10.2531C13.8314 9.7069 14.3535 9.18481 14.6266 8.68682Z"
        fill="currentColor"
        key="uMi80VneH"
      />
      <path
        d="M8.28925 16.0001C8.28925 16.5524 8.73697 17.0001 9.28925 17.0001H10.1085C10.6608 17.0001 11.1085 16.5524 11.1085 16.0001V15.3736C11.1085 14.8213 10.6608 14.3736 10.1085 14.3736H9.28925C8.73697 14.3736 8.28925 14.8213 8.28925 15.3736V16.0001Z"
        fill="currentColor"
        key="SueARwNpp3"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
