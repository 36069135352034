import React from 'react'
export const link = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3636 3.63641C14.8015 2.07431 12.2688 2.07431 10.7067 3.63641L7.8783 6.46484C6.31621 8.02693 6.31654 10.5594 7.87864 12.1215C8.26917 12.512 8.90233 12.512 9.29286 12.1215C9.68338 11.731 9.68338 11.0978 9.29286 10.7073L9.27675 10.6915C8.5115 9.90929 8.51676 8.65481 9.29252 7.87905L12.1209 5.05062C12.902 4.26957 14.1683 4.26958 14.9494 5.05062C15.7304 5.83167 15.7304 7.098 14.9494 7.87905L13.9481 8.88034C14.2651 9.68938 14.3633 10.5644 14.2426 11.4142L16.3636 9.29326C17.9257 7.73117 17.9257 5.19851 16.3636 3.63641ZM3.63628 16.364C5.19838 17.9261 7.73104 17.9261 9.29314 16.364L12.1216 13.5356C13.6837 11.9735 13.6837 9.4408 12.1216 7.8787C11.731 7.48818 11.0979 7.48818 10.7074 7.8787C10.3168 8.26923 10.3168 8.90239 10.7074 9.29292C11.4884 10.074 11.4884 11.3403 10.7074 12.1213L7.87892 14.9498C7.09788 15.7308 5.83155 15.7308 5.0505 14.9498C4.26945 14.1687 4.26945 12.9024 5.0505 12.1213L6.05179 11.12C5.73476 10.311 5.63658 9.43603 5.75726 8.58615L3.63628 10.7071C2.07419 12.2692 2.07419 14.8019 3.63628 16.364Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
}
