import React from 'react'
export const gusd_circle_mono = {
  path: (
    <>
      <path
        d="M16 29.3333C23.3638 29.3333 29.3333 23.3638 29.3333 16C29.3333 8.63621 23.3638 2.66667 16 2.66667C8.63621 2.66667 2.66667 8.63621 2.66667 16C2.66667 23.3638 8.63621 29.3333 16 29.3333Z"
        fill="#787A9B"
        key="els1Oh1KU"
      />
      <path
        d="M18.4565 8C15.6424 8 13.2518 10.1647 12.9506 12.9506C10.1647 13.2518 8 15.6424 8 18.4565C8 21.5153 10.4847 24 13.5435 24C16.3576 24 18.7576 21.8353 19.0494 19.0494C21.8353 18.7482 24 16.3576 24 13.5435C24 10.4847 21.5153 8 18.4565 8V8ZM22.7012 14.1647C22.5626 15.0737 22.1372 15.9146 21.4871 16.5647C20.8369 17.2149 19.996 17.6403 19.0871 17.7788V14.1647H22.7012ZM9.29882 17.8353C9.43554 16.9246 9.86012 16.0816 10.5104 15.4296C11.1607 14.7776 12.0026 14.3509 12.9129 14.2118V17.8259H9.29882V17.8353ZM17.7882 19.0871C17.638 20.1052 17.1265 21.0353 16.3472 21.7075C15.5678 22.3797 14.5727 22.7491 13.5435 22.7482C11.4071 22.7482 9.60941 21.1859 9.29882 19.0871H17.7882V19.0871ZM17.8353 14.1647V17.8259H14.1647V14.1647H17.8353ZM22.7012 12.9129H14.2118C14.362 11.8948 14.8735 10.9647 15.6528 10.2925C16.4322 9.62031 17.4273 9.25091 18.4565 9.25176C20.5929 9.25176 22.3906 10.8141 22.7012 12.9129Z"
        fill="white"
        key="wu_dVXkhRG"
      />
    </>
  ),
  viewBox: '0 0 32 32',
}
