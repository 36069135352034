import React from 'react'

export const bar_chart = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2 0.599976C7.92386 0.599976 7.7 0.823834 7.7 1.09998V11.4H9.5V1.09998C9.5 0.823833 9.27614 0.599976 9 0.599976H8.2ZM4.1 3.79998C4.1 3.52383 4.32386 3.29998 4.6 3.29998H5.4C5.67614 3.29998 5.9 3.52383 5.9 3.79998V11.4H4.1V3.79998ZM0.5 6.49998C0.5 6.22383 0.723858 5.99998 1 5.99998H1.8C2.07614 5.99998 2.3 6.22383 2.3 6.49998V11.4H0.5V6.49998Z"
      fill="#17344F"
    />
  ),
  viewBox: '0 0 10 12',
}
