import React from 'react'
export const wallet_connect_color = {
  path: (
    <g key="JWNNq0jlo">
      <path
        d="M6.09607 8.54776C9.35682 5.18265 14.6435 5.18265 17.9043 8.54776L18.2967 8.95276C18.4598 9.12101 18.4598 9.39381 18.2967 9.56207L16.9543 10.9475C16.8728 11.0316 16.7406 11.0316 16.6591 10.9475L16.119 10.3902C13.8442 8.04257 10.1561 8.04257 7.88132 10.3902L7.30299 10.987C7.22147 11.0711 7.0893 11.0711 7.00778 10.987L5.66533 9.60159C5.5023 9.43333 5.5023 9.16054 5.66533 8.99228L6.09607 8.54776ZM20.6806 11.4129L21.8754 12.646C22.0384 12.8142 22.0384 13.087 21.8754 13.2553L16.488 18.8151C16.325 18.9834 16.0607 18.9834 15.8976 18.8151C15.8976 18.8151 15.8976 18.8151 15.8976 18.8151L12.074 14.8691C12.0332 14.8271 11.9672 14.8271 11.9264 14.8691C11.9264 14.8691 11.9264 14.8691 11.9264 14.8691L8.10287 18.8151C7.93984 18.9834 7.6755 18.9834 7.51246 18.8151C7.51246 18.8151 7.51246 18.8151 7.51246 18.8151L2.12496 13.2552C1.96193 13.0869 1.96193 12.8142 2.12496 12.6459L3.31975 11.4129C3.48278 11.2446 3.74712 11.2446 3.91016 11.4129L7.73382 15.3589C7.77458 15.401 7.84066 15.401 7.88142 15.3589C7.88142 15.3589 7.88143 15.3589 7.88143 15.3589L11.7049 11.4129C11.8679 11.2446 12.1323 11.2446 12.2953 11.4129C12.2953 11.4129 12.2953 11.4129 12.2953 11.4129L16.119 15.3589C16.1597 15.401 16.2258 15.401 16.2666 15.3589L20.0902 11.4129C20.2532 11.2447 20.5176 11.2447 20.6806 11.4129Z"
        fill="#5399F5"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
