import React from 'react'
export const notification = {
  path: (
    <g key="q6AwUvVZY">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9813 4.27473C11.9936 4.18492 12 4.0932 12 4C12 2.89543 11.1046 2 10 2C8.89543 2 8 2.89543 8 4C8 4.09321 8.00638 4.18493 8.01872 4.27475C6.74659 4.65995 5.80668 5.42619 5.16137 6.4198C4.26174 7.80502 4.00003 9.52773 4.00003 11C4.00003 12.1043 3.6173 12.5587 3.37077 12.7583C3.22845 12.8736 3.0861 12.9394 2.98268 12.9755C2.93165 12.9934 2.89302 13.0029 2.87305 13.0073L2.86175 13.0096C2.34639 13.0814 1.97079 13.5377 2.00179 14.0593C2.03319 14.5876 2.47078 15 3.00003 15H17C17.5293 15 17.9669 14.5876 17.9983 14.0593C18.0293 13.5377 17.6537 13.0814 17.1383 13.0096L17.127 13.0073C17.107 13.0029 17.0684 12.9934 17.0174 12.9755C16.914 12.9394 16.7716 12.8736 16.6293 12.7583C16.3828 12.5587 16 12.1043 16 11C16 9.52773 15.7383 7.80502 14.8387 6.4198C14.1934 5.42617 13.2534 4.65993 11.9813 4.27473ZM17.1343 13.0091L17.1383 13.0096L17.1407 13.01L17.1343 13.0091ZM17.1343 13.0091L17.121 13.0073L17.1234 13.0076L17.1277 13.0082L17.1343 13.0091ZM6.83868 7.50913C6.23831 8.43355 6.00003 9.71085 6.00003 11C6.00003 11.8039 5.85477 12.4639 5.61916 13H14.3809C14.1453 12.4639 14 11.8039 14 11C14 9.71085 13.7617 8.43355 13.1614 7.50913C12.605 6.65251 11.6787 6 10 6C8.32133 6 7.39502 6.65251 6.83868 7.50913Z"
        fill="currentColor"
        key="ou1a892M7"
      />
      <path
        d="M8 16C8 16.3643 8.09739 16.7058 8.26756 17C8.61337 17.5978 9.25972 18 10 18C10.7403 18 11.3866 17.5978 11.7324 17C11.9026 16.7058 12 16.3643 12 16H8Z"
        fill="currentColor"
        key="ocZw41af2c"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
