import React from 'react'
export const wbtc_color = {
  path: (
    <g key="vFmVHstm_">
      <circle cx="12" cy="12" r="9.31034" fill="white" key="vcR83JJ2B" />
      <circle cx="12" cy="12" r="10" fill="#E6E6E6" key="Fj4lJ9KytE" />
      <path
        d="M17.25 6.24513C14.2799 3.52904 9.72779 3.52904 6.75762 6.24513L6.19566 5.69598C9.47298 2.67328 14.5219 2.67328 17.7992 5.69598L17.25 6.24513Z"
        fill="#6A6672"
        key="IIIek9r8hD"
      />
      <path
        d="M17.7589 6.74659L18.3081 6.19744V6.18463C21.3308 9.46195 21.3308 14.511 18.3081 17.7881L17.7589 17.239C20.4748 14.2688 20.4748 9.71675 17.7589 6.74659Z"
        fill="#6A6672"
        key="XCoQ6IjYTr"
      />
      <path
        d="M6.24513 17.2445C3.53252 14.2751 3.53252 9.72703 6.24513 6.7576L5.69598 6.20845C2.67328 9.48577 2.67328 14.5346 5.69598 17.812L6.24513 17.2445Z"
        fill="#6A6672"
        key="4dGtb7vyN_"
      />
      <path
        d="M6.75205 17.7514C9.72221 20.4675 14.2743 20.4675 17.2445 17.7514L17.8064 18.3006C14.5291 21.3233 9.48022 21.3233 6.2029 18.3006L6.75205 17.7514Z"
        fill="#6A6672"
        key="bWIFpLLBrB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6639 10.6014C13.6639 11.6997 11.9469 11.5661 11.4014 11.5661V9.63126C11.9469 9.63126 13.6639 9.45919 13.6639 10.6014ZM14.1124 13.6053C14.1124 14.8061 12.0549 14.6688 11.3996 14.6688V12.5381C12.0549 12.5399 14.1124 12.3514 14.1124 13.6053ZM15.467 10.1676C15.3572 9.0217 14.3687 8.6373 13.1185 8.52015V6.94043H12.1519V8.48903C11.8975 8.48903 11.6376 8.48903 11.3795 8.49818V6.94043H10.4185V8.5293H9.80342H8.45984V9.56353C8.45984 9.56353 9.17373 9.55072 9.16275 9.56353C9.43037 9.53406 9.67309 9.72187 9.7119 9.98821V14.3393C9.70018 14.528 9.53782 14.6713 9.34909 14.6598C9.34799 14.6598 9.3469 14.6598 9.3458 14.6596C9.35861 14.6706 8.64289 14.6596 8.64289 14.6596L8.45068 15.8147H9.71007H10.402V17.4292H11.3685V15.8385H12.141V17.4219H13.1093V15.8257C14.7421 15.7268 15.8807 15.3241 16.0235 13.7956C16.1388 12.5655 15.5603 12.0164 14.6359 11.7949C15.1979 11.5093 15.5512 11.006 15.467 10.1676Z"
        fill="#F09242"
        key="xr4be6iWdZ"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9982 2.77979C6.90903 2.7829 2.78583 6.91086 2.78894 12C2.79206 17.0891 6.92001 21.2123 12.0092 21.2092C17.0948 21.2063 21.2164 17.0837 21.2184 11.9982C21.2124 6.90976 17.0866 2.7873 11.9982 2.78528V2.77979ZM11.9982 22C6.47538 21.9991 1.9989 17.5211 2 11.9982C2.0011 6.4752 6.47905 1.9989 12.0018 2C17.5234 2.0011 21.9991 6.47666 22 11.9982C21.994 17.5195 17.5195 21.994 11.9982 22Z"
        fill="#3D374B"
        key="rVhrQznaON"
      />
      <path
        d="M15.9999 13.984C15.9326 14.411 15.7789 14.7413 15.5522 14.9966L14.0758 13.3357C14.0026 13.0843 13.8244 12.9101 13.5907 12.7899L12.4779 11.538C13.0753 11.4654 13.6638 11.2447 13.6638 10.6017C13.6638 9.57402 12.274 9.61021 11.598 9.62781C11.5226 9.62977 11.4561 9.6315 11.4013 9.6315V10.3269L9.80371 8.52954H10.4184V6.94067H11.3794V8.49843C11.6375 8.48927 11.8974 8.48927 12.1518 8.48927V6.94067H13.1183V8.52039C14.3686 8.63754 15.357 9.02195 15.4669 10.1678C15.5511 11.0062 15.1978 11.5096 14.6358 11.7951C15.3524 11.9668 15.8611 12.3355 15.9999 13.0655V13.984Z"
        fill="#F3A868"
        key="K9VeST_wz5"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
