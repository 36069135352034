import React from 'react'
export const moonpay = {
  path: (
    <>
      <path
        d="M-0.000143051 18.9883C-0.000143051 27.279 6.7091 34 14.9854 34C23.2616 34 29.9709 27.279 29.9709 18.9883C29.9709 10.6975 23.2616 3.97656 14.9854 3.97656C6.7091 3.97656 -0.000143051 10.6975 -0.000143051 18.9883Z"
        fill="black"
        key="H_DLof7QY"
      />
      <path
        d="M27.7887 4.07566C27.7887 6.32658 29.6103 8.15132 31.8573 8.15132C34.1043 8.15132 35.9259 6.32658 35.9259 4.07566C35.9259 1.82473 34.1043 0 31.8573 0C29.6103 0 27.7887 1.82473 27.7887 4.07566Z"
        fill="black"
        key="U1TGyjaLaJ"
      />
    </>
  ),
  viewBox: '0 0 36 34',
}
