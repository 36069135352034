import React from 'react'
export const arrow_up_thin = {
  path: (
    <path
      d="M6.4502 16.2727H8.38912L8.38912 4.54972L12.871 9.03161L14.1984 7.68928L7.41966 0.910511L0.625977 7.68928L1.98322 9.03161L6.4502 4.54972L6.4502 16.2727Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 15 17',
}
