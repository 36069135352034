import React from 'react'
export const telegram = {
  path: (
    <g key="frkuRAZOB">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C5.58267 2 2 5.58133 2 10C2 14.418 5.58267 18 10 18C14.418 18 18 14.418 18 10C18 5.58133 14.418 2 10 2ZM12.1493 13.914C12.2747 14.0027 12.436 14.0247 12.58 13.9707C12.7233 13.916 12.8293 13.7927 12.8613 13.6433C13.1993 12.0553 14.0193 8.03533 14.3267 6.59133C14.35 6.482 14.3113 6.36867 14.226 6.296C14.14 6.22333 14.0213 6.20267 13.916 6.24133C12.2853 6.84533 7.26333 8.72933 5.21067 9.48867C5.08067 9.53733 4.996 9.662 5 9.8C5.00467 9.93733 5.09733 10.0567 5.23067 10.0967C6.15133 10.372 7.35933 10.7553 7.35933 10.7553C7.35933 10.7553 7.924 12.4607 8.218 13.3273C8.25533 13.4367 8.34067 13.522 8.45267 13.5513C8.56533 13.5807 8.68467 13.55 8.76867 13.4707C9.24133 13.0247 9.972 12.3347 9.972 12.3347C9.972 12.3347 11.3613 13.3527 12.1493 13.914ZM7.86733 10.5393L8.52067 12.6933L8.666 11.3293C8.666 11.3293 11.188 9.054 12.6267 7.75733C12.6687 7.71933 12.674 7.65533 12.6393 7.61067C12.6047 7.566 12.5407 7.55533 12.4933 7.586C10.8267 8.65 7.86733 10.5393 7.86733 10.5393Z"
        fill="currentColor"
      />
    </g>
  ),
  viewBox: '0 0 20 20',
}
