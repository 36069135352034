import React from 'react'
export const sign_transaction = {
  path: (
    <>
      <path
        d="M4.13215 13.4287L1.57348 10.8701C1.49143 10.789 1.38864 10.732 1.27636 10.7054C1.16408 10.6788 1.04666 10.6836 0.936939 10.7193C0.827221 10.755 0.729446 10.8203 0.654324 10.9078C0.579202 10.9954 0.52963 11.102 0.511039 11.2158L0.0081076 14.2712C-0.00658072 14.3617 -0.00126543 14.4544 0.0236804 14.5427C0.0486262 14.631 0.0925977 14.7128 0.152508 14.7823C0.212418 14.8518 0.286814 14.9073 0.370473 14.945C0.454132 14.9827 0.545026 15.0016 0.636772 15.0004H0.737358L3.79267 14.4975C3.90789 14.4798 4.01594 14.4305 4.10475 14.355C4.19357 14.2796 4.25964 14.1809 4.2956 14.07C4.33067 13.9585 4.33395 13.8394 4.30507 13.7261C4.2762 13.6128 4.21632 13.5099 4.13215 13.4287ZM1.0454 13.9568L1.32202 12.2783L2.72394 13.6802L1.0454 13.9568Z"
        fill="#FDC134"
        key="Dcm_dRUwT"
      />
      <path
        d="M14.8286 2.74392L12.2735 0.182518C12.2147 0.12419 12.1449 0.078044 12.0683 0.0467254C11.9916 0.0154067 11.9095 -0.000468339 11.8267 1.05189e-05C11.6616 0.000705697 11.5033 0.0662518 11.3861 0.182518L0.687401 10.8812C0.629073 10.94 0.582927 11.0098 0.551608 11.0865C0.52029 11.1631 0.504414 11.2452 0.504893 11.3281C0.505589 11.4932 0.571135 11.6514 0.687401 11.7686L3.2488 14.33C3.36671 14.4472 3.52622 14.513 3.69248 14.513C3.85874 14.513 4.01825 14.4472 4.13616 14.33L14.8349 3.63128C14.9513 3.51254 15.0159 3.35256 15.0147 3.1863C15.0136 3.02004 14.9466 2.861 14.8286 2.74392ZM12.5944 4.09699L10.9204 2.42296L11.8267 1.523L13.4944 3.19075L12.5944 4.09699Z"
        fill="#F08B04"
        key="McC5Mggn5N"
      />
    </>
  ),
  viewBox: '0 0 16 15',
}
