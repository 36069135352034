import React from 'react'
export const pax_color = {
  path: (
    <g key="moIvTUrf7">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55867 5.47589C7.94116 5.04 8.28974 4.66798 8.62275 4.35647C2.99434 4.17382 4.63272 8.27387 2.79865 12.4406C0.686607 17.2393 3.04716 19.068 6.56464 19.6378C6.07707 19.0579 5.67901 18.3946 5.37602 17.6785C3.09553 14.7853 4.0134 9.5143 7.55867 5.47589Z"
        fill="#B3D234"
        key="A7zD4Jdvh"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1022 10.5874C20.5785 12.0738 19.6172 13.7417 18.2585 15.3833C17.1994 16.6624 16.0219 17.6698 14.8066 18.3992C13.4017 19.3164 11.6588 19.8576 9.71025 19.8576C8.61948 19.8576 7.54706 19.7965 6.5647 19.6374C7.14511 20.3271 7.8506 20.8997 8.67118 21.2992C13.7598 23.7789 14.0811 19.1724 19.4649 16.835C22.4881 15.5222 22.4993 12.9562 21.1022 10.5874Z"
        fill="#0095D9"
        key="IPJt7XD0Kc"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8049 5.79366C15.5555 5.93148 16.2676 6.18024 16.9053 6.5433C18.6143 7.5176 20.1709 9.00848 21.1021 10.5873C22.0189 7.98543 21.5919 5.93989 19.5861 5.56955C17.3818 5.16336 15.6583 5.03281 14.0021 3.86745C11.9624 2.43205 10.1745 2.90547 8.6228 4.35657C8.73232 4.35993 8.84407 4.36497 8.95915 4.3717C11.1118 4.49944 13.166 4.92188 14.8049 5.79366Z"
        fill="#EEE809"
        key="BosMc9enM5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55876 5.47562C4.01349 9.51403 3.09562 14.785 5.37611 17.6783C4.35206 15.2562 4.42322 12.2353 5.86646 9.8233C7.78781 6.61184 11.6416 5.21173 14.805 5.79385C13.166 4.92151 11.1118 4.49963 8.95975 4.37133C8.84411 4.36461 8.73237 4.36012 8.62285 4.3562C8.28983 4.66771 7.94125 5.03973 7.55876 5.47562Z"
        fill="#51B849"
        key="iwMe6mRP4L"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9048 6.54334C16.2671 6.17973 15.555 5.93153 14.8044 5.7937C16.9626 6.94169 18.3986 8.8707 18.3825 11.9432C18.3681 14.609 17.0015 16.9649 14.8061 18.3992C16.0214 17.6697 17.1995 16.6624 18.258 15.3833C19.6173 13.7417 20.578 12.0738 21.1017 10.5874C20.1704 9.00852 18.6138 7.51709 16.9048 6.54334Z"
        fill="#00845D"
        key="v6VdORGUrn"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4495 16.9387C12.0128 18.5164 8.76386 17.8127 7.1922 15.3666C5.62053 12.9205 6.39775 9.77065 8.75774 8.07976C11.3968 6.18941 14.7814 6.93513 16.0151 9.65187C17.2181 12.3008 16.8857 15.361 14.4495 16.9387ZM14.8048 5.79386C11.6414 5.2123 7.78762 6.61185 5.86626 9.82331C4.42302 12.2353 4.35131 15.2568 5.37592 17.6783C5.67891 18.3943 6.07641 19.0582 6.56453 19.6381C7.54689 19.7972 8.61931 19.8577 9.70953 19.8577C11.6587 19.8577 13.4016 19.3165 14.8059 18.3993C17.0019 16.9656 18.3684 14.6091 18.3823 11.9434C18.399 8.87086 16.9624 6.94185 14.8048 5.79386Z"
        fill="#005121"
        key="tXtPs2_EmY"
      />
    </g>
  ),
  viewBox: '0 0 24 24',
}
