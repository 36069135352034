import React from 'react'
export const wbtc_usdc = {
  path: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 25C24.9706 25 29 20.9706 29 16C29 11.0294 24.9706 7 20 7C15.0294 7 11 11.0294 11 16C11 20.9706 15.0294 25 20 25Z"
        fill="url(#paint0_linear_13180_1064)"
        key="3AAgkF5KS"
      />
      <path
        d="M18.35 22.225C18.35 22.45 18.2 22.525 17.975 22.525C15.2 21.625 13.25 19.075 13.25 16.075C13.25 13.075 15.2 10.525 17.975 9.62498C18.2 9.54998 18.35 9.69998 18.35 9.92498V10.45C18.35 10.6 18.275 10.75 18.125 10.825C15.95 11.65 14.45 13.675 14.45 16.075C14.45 18.475 16.025 20.575 18.125 21.325C18.275 21.4 18.35 21.55 18.35 21.7V22.225Z"
        fill="white"
        key="He4uD6mEJ0"
      />
      <path
        d="M20.5998 20.3499C20.5998 20.4999 20.4498 20.65 20.2998 20.65H19.6998C19.5498 20.65 19.3998 20.4999 19.3998 20.3499V19.45C18.1998 19.3 17.5998 18.625 17.3748 17.65C17.3748 17.5 17.4498 17.35 17.5998 17.35H18.2748C18.4248 17.35 18.4998 17.425 18.5748 17.575C18.7248 18.1 19.0248 18.55 19.9998 18.55C20.7498 18.55 21.2748 18.175 21.2748 17.575C21.2748 16.975 20.9748 16.75 19.9248 16.6C18.3498 16.375 17.5998 15.925 17.5998 14.65C17.5998 13.675 18.3498 12.925 19.3998 12.775V11.875C19.3998 11.725 19.5498 11.575 19.6998 11.575H20.2998C20.4498 11.575 20.5998 11.725 20.5998 11.875V12.775C21.4998 12.925 22.0998 13.45 22.2498 14.275C22.2498 14.425 22.1748 14.575 22.0248 14.575H21.4248C21.2748 14.575 21.1998 14.5 21.1248 14.35C20.9748 13.825 20.5998 13.6 19.9248 13.6C19.1748 13.6 18.7998 13.975 18.7998 14.5C18.7998 15.025 19.0248 15.325 20.1498 15.475C21.7248 15.7 22.4748 16.15 22.4748 17.425C22.4748 18.4 21.7248 19.225 20.5998 19.45V20.3499Z"
        fill="white"
        key="UUkKSXWLro"
      />
      <path
        d="M22.0249 22.525C21.7999 22.6 21.6499 22.45 21.6499 22.225V21.7C21.6499 21.55 21.7249 21.4 21.8749 21.325C24.0499 20.5 25.5499 18.475 25.5499 16.075C25.5499 13.675 23.9749 11.575 21.8749 10.825C21.7249 10.75 21.6499 10.6 21.6499 10.45V9.925C21.6499 9.7 21.7999 9.625 22.0249 9.625C24.7249 10.525 26.7499 13.075 26.7499 16.075C26.7499 19.075 24.7999 21.625 22.0249 22.525Z"
        fill="white"
        key="5n8mjQ72iZ"
      />
      <circle cx="12" cy="16" r="9" fill="white" key="fewvglRjTA" />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7255 10.819C14.0524 8.3745 9.95552 8.3745 7.28237 10.819L6.7766 10.3247C9.7262 7.60432 14.2702 7.60432 17.2198 10.3247L16.7255 10.819ZM17.1845 11.2707L17.6788 10.7764V10.7649C20.3992 13.7145 20.3992 18.2586 17.6788 21.2081L17.1845 20.7138C19.6288 18.0407 19.6288 13.9438 17.1845 11.2707ZM6.82215 20.718C4.3808 18.0455 4.3808 13.9523 6.82215 11.2798L6.32792 10.7856C3.60749 13.7352 3.60749 18.2792 6.32792 21.2287L6.82215 20.718ZM7.27808 21.1744C9.95123 23.6188 14.0481 23.6188 16.7213 21.1744L17.227 21.6686C14.2774 24.389 9.73343 24.389 6.78384 21.6686L7.27808 21.1744Z"
        fill="#6A6672"
        key="fsLt4eIYj9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4983 14.7414C13.4983 15.7299 11.953 15.6096 11.4621 15.6096V13.8683C11.953 13.8683 13.4983 13.7134 13.4983 14.7414ZM13.902 17.4449C13.902 18.5256 12.0502 18.4021 11.4604 18.4021V16.4844C12.0502 16.4861 13.902 16.3164 13.902 17.4449ZM15.1211 14.351C15.0222 13.3197 14.1326 12.9737 13.0074 12.8683V11.4465H12.1375V12.8403C11.9085 12.8403 11.6746 12.8403 11.4423 12.8485V11.4465H10.5774V12.8765H10.0239H8.81463V13.8073C8.81463 13.8073 9.45714 13.7958 9.44725 13.8073C9.68811 13.7808 9.90656 13.9498 9.94149 14.1895V18.1055C9.93094 18.2754 9.78482 18.4044 9.61496 18.394C9.61398 18.394 9.61299 18.394 9.612 18.3938C9.62353 18.4037 8.97938 18.3938 8.97938 18.3938L8.8064 19.4334H9.93984H10.5626V20.8864H11.4324V19.4548H12.1277V20.8798H12.9992V19.4432C14.4687 19.3543 15.4934 18.9918 15.6219 17.6162C15.7257 16.5091 15.2051 16.0149 14.3731 15.8156C14.8789 15.5586 15.1968 15.1055 15.1211 14.351Z"
        fill="url(#paint1_linear_13180_1064)"
        key="zfqhwOzp0b"
      />
      <mask
        id="mask0_13180_1064"
        key="mask0_13180_1064"
        maskUnits="userSpaceOnUse"
        x="3"
        y="7"
        width="19"
        height="18"
      >
        <path d="M3.00098 7H21.001V25H3.00098V7Z" fill="white" key="ZbbbfF_6t4" />
      </mask>
      <g mask="url(#mask0_13180_1064)">
        <path
          opacity="0.4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9993 7.70181C7.4191 7.70461 3.70823 11.4198 3.71103 16C3.71383 20.5802 7.42899 24.2911 12.0092 24.2883C16.5863 24.2857 20.2957 20.5753 20.2975 15.9984C20.2921 11.4188 16.5789 7.70857 11.9993 7.70675V7.70181ZM11.9993 25C7.02882 24.9992 2.99999 20.969 3.00098 15.9984C3.00197 11.0277 7.03212 6.99901 12.0026 7C16.972 7.00099 21.0002 11.029 21.001 15.9984C20.9956 20.9675 16.9685 24.9946 11.9993 25Z"
          fill="#6A6672"
          key="GyCw6R6wJg"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_13180_1064"
          key="paint0_linear_13180_1064"
          x1="15.9111"
          y1="6.77485"
          x2="25.2531"
          y2="24.6826"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0666CE" key="vssW0cVQIN" />
          <stop offset="1" stopColor="#61A9F8" key="x2RvtZ1ZXZ" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13180_1064"
          key="paint1_linear_13180_1064"
          x1="8.8064"
          y1="11.4465"
          x2="15.5491"
          y2="19.1501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F48702" key="qJRlUcXTEK" />
          <stop offset="1" stopColor="#FCBD71" key="mfx1A0Oxzy" />
        </linearGradient>
      </defs>
    </svg>
  ),
}
