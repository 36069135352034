import React from 'react'
export const steth_eth = {
  path: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="16" r="9" fill="url(#paint0_linear_13180_1139)" key="7ISuloUCC" />
      <path
        d="M20.2304 10.4614V18.1121L16.5381 16.1026L20.2304 10.4614Z"
        fill="#FEFBFD"
        key="DSBjHrvws6"
      />
      <path
        d="M20.2304 10.4614L23.9226 16.1026L20.2304 18.1121V10.4614Z"
        fill="#FEFBFD"
        key="pMdHlMmCuw"
      />
      <path
        d="M20.2303 18.756V21.5383L16.5381 16.7476L20.2303 18.756Z"
        fill="#FEFBFD"
        key="fvgph-0vRr"
      />
      <path
        d="M20.2303 21.5383V18.756L23.9227 16.7476L20.2303 21.5383Z"
        fill="#FEFBFD"
        key="Wr7vcbD8zA"
      />
      <path
        d="M12 25C16.9706 25 21 20.9706 21 16C21 11.0294 16.9706 7 12 7C7.02944 7 3 11.0294 3 16C3 20.9706 7.02944 25 12 25Z"
        fill="url(#paint1_linear_13180_1139)"
        key="xC9drZEzdH"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1612 14.5669L12.0042 9.63696L8.84719 14.5669L12.0039 16.4028L15.1612 14.5669ZM12.0042 10.9131L9.81363 14.3338L12.0039 15.6075L14.1948 14.3338L12.0042 10.9131ZM8.33752 15.357L12.0001 17.4864L15.6637 15.357L15.7639 15.5131C16.1933 16.1874 16.4335 16.9647 16.4594 17.7637C16.4854 18.5627 16.2962 19.354 15.9116 20.0548C15.527 20.7556 14.9611 21.3401 14.2732 21.7473C13.5853 22.1545 12.8006 22.3693 12.0012 22.3693C11.2018 22.3693 10.4171 22.1545 9.72913 21.7473C9.0412 21.3401 8.47533 20.7556 8.09073 20.0548C7.70612 19.354 7.51691 18.5627 7.54288 17.7637C7.56886 16.9647 7.80907 16.1874 8.23838 15.5131L8.33752 15.357Z"
        fill="white"
        key="cPWalrsl9U"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13180_1139"
          key="paint0_linear_13180_1139"
          x1="11.6388"
          y1="16.0003"
          x2="28.8899"
          y2="29.5003"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6580EB" key="fbSo0lR_yt" />
          <stop offset="1" stopColor="#8EA2F2" key="1C2KQP8TSf" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13180_1139"
          key="paint1_linear_13180_1139"
          x1="6.26249"
          y1="9.58728"
          x2="16.05"
          y2="22.7498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A3FF" key="ueTO508VOX" />
          <stop offset="1" stopColor="#0B91DD" key="BIz84YxL7f" />
        </linearGradient>
      </defs>
    </svg>
  ),
}
