import React from 'react'
export const sai_color = {
  path: (
    <g key="74S-Tdo9t">
      <path d="M2 12L12 2L22 12L12 22L2 12Z" fill="#FDCB7F" key="Lj5u4TcI8" />
      <path d="M2 12L12 2L22 12L12 16L2 12Z" fill="#FFB74D" key="j0YxoPCp8c" />
      <path d="M5 12L12 5L19 12H15L12 8.99999L9 12H5Z" fill="#FCFCFC" key="SY95cWjC-t" />
    </g>
  ),
  viewBox: '0 0 24 24',
}
