import React from 'react'
export const support_minus = {
  path: (
    <path
      d="M9.42857 2.64295C9.42857 3.11634 9.81232 2.64295 10.2857 2.64295C10.7591 2.64295 11.1429 3.11634 11.1429 2.64295H19.7143C20.1877 2.64295 20.5714 2.2592 20.5714 1.78581C20.5714 1.31242 20.1877 0.928667 19.7143 0.928667H11.1429L14.5 1.49993C14.5 1.02654 10.7591 0.928406 10.2857 0.928406C10.2857 0.928406 6 1.02655 6 1.49994L9.42857 0.928667H0.857143C0.383756 0.928667 0 1.31242 0 1.78581C0 2.2592 0.383756 2.64295 0.857143 2.64295H9.42857Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 21 3',
}
